import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';


class UsuariosTranslated extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span className="">Usuarios</span>
                </div>
                <div className="list-component-usuarios">
                    <div className="tarifa-nombre">
                        <FontAwesomeIcon icon="user-tie" className="icon" />
                        <span>B42532986</span>
                    </div>
                    <div className="tarifa-nombre">
                        <span className="tarifa">Nombre:  </span>
                        <span className="tarifa-nombre-des">ZETA AIR SL</span>
                    </div>
                    <div className="tarifa-telefono">
                        <div className="icon-right" >
                            <FontAwesomeIcon icon="chevron-right" className="icon" />
                        </div>
                    </div>
                    <div className="list-component-asociado">
                        <div className="tarifa-nombre">

                            <span className="tarifa">Usuario: </span>
                            <span className="tarifa-nombre-des">B42532986</span>
                        </div>
                        <div className="tarifa-telefono">
                            <span className="tarifa">Pasword: </span>
                            <span className="tarifa-nombre-des">*********</span>
                        </div>
                    </div>
                    <hr />
                    <div className="tarifa-nombre">
                        <FontAwesomeIcon icon="user-tie" className="icon" />
                        <span>22133326N</span>
                    </div>
                    <div className="tarifa-nombre">
                        <span className="tarifa">Nombre:  </span>
                        <span className="tarifa-nombre-des">GUILLERMO</span>
                    </div>
                    <div className="tarifa-telefono">
                        <div className="icon-right" >
                            <FontAwesomeIcon icon="chevron-right" className="icon" />
                        </div>
                    </div>
                    <div className="list-component-asociado">
                        <div className="tarifa-nombre">

                            <span className="tarifa">Usuario: </span>
                            <span className="tarifa-nombre-des">22133326N</span>
                        </div>
                        <div className="tarifa-telefono">
                            <span className="tarifa">Pasword: </span>
                            <span className="tarifa-nombre-des">*********</span>
                        </div>
                    </div>
                    <hr />
                    <div className="tarifa-nombre">
                        <FontAwesomeIcon icon="user-tie" className="icon" />
                        <span>74230975Q</span>
                    </div>
                    <div className="tarifa-nombre">
                        <span className="tarifa">Nombre:  </span>
                        <span className="tarifa-nombre-des">TERESA MONTERO</span>
                    </div>
                    <div className="tarifa-telefono">
                        <div className="icon-right" >
                            <FontAwesomeIcon icon="chevron-right" className="icon" />
                        </div>
                    </div>
                    <div className="list-component-asociado">
                        <div className="tarifa-nombre">

                            <span className="tarifa">Usuario: </span>
                            <span className="tarifa-nombre-des">74230975Q</span>
                        </div>
                        <div className="tarifa-telefono">
                            <span className="tarifa">Pasword: </span>
                            <span className="tarifa-nombre-des">*********</span>
                        </div>
                    </div>

                </div>
                <PiePagina />
            </div>
        );

    }



}

const Usuarios = withTranslation('common')(UsuariosTranslated)
export default Usuarios;