import React from 'react';
import api from '../servicios/api';
import md5 from 'md5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../styles/GlobalComponents.css";
import { IonItem, IonLabel } from '@ionic/react';
import { withTranslation } from "react-i18next";
import Util from '../servicios/Util';
import PageError from '../PantallaCarga/PageError';
import PageLoading from "../PantallaCarga/PageLoading";
import { NativeBiometric } from 'capacitor-native-biometric';
import Alertas from '../../components/servicios/Alertas';
import { IonAlert } from '@ionic/react';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';
import PiePagina from '../../components/Navbar/PiePagina';



let respuesta;
class LopdTranslated extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                lopd: '',
                nolopd: '',
                publicidad: '',
                nopublicidad: ''
            },
            error: false,
            loading: false,
            alert: false,
            alertOk: false,
            alertKo: false,
            texto: ''
        };
    }

    componentDidMount() {
        const empresa = JSON.parse(localStorage.getItem('empresa'));
        this.setState({ texto: empresa.texto_lopd });
    }
    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }
    abrirAlert = () => {
        this.setState({ alert: !this.state.alert });
    }


    handleChange(e) {
        console.log('first')
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }

        });
        console.log(this.state.form)
    }


    handleSubmit(e) {
        e.preventDefault();
        // Lee los datos del formulario
        const form = e.target;
        const formData = new FormData(form);

        // Puedes pasar formData como el cuerpo de la consulta directamente:
        //fetch('/some-api', { method: form.method, body: formData });

        const formJson = Object.fromEntries(formData.entries());
        console.log(formJson);
    }


    inicioSesion = async () => {

        const cliente = JSON.parse(localStorage.getItem('user'));
        const idioma = this.props.i18n.language;

        if (cliente.password === md5(this.state.form.contraAntigua)) {


            if ((this.state.form.contraseña) !== (this.state.form.contraseñaRepeat) || (this.state.form.contraseña) === '' || (this.state.form.contraseñaRepeat) === '') {
                this.abrirAlert();
            } else if (Util.verificarpass(this.state.form.contraseña, idioma) !== 'ok') {
                Alertas.verificarPass(Util.verificarpass(this.state.form.contraseña, idioma), idioma);

            }

            else {
                this.setState({ loading: true, error: null });
                if (cliente.esUsuario != '' && cliente.esUsuario != null && cliente.esUsuario != undefined) {
                    await api.Clientes.modificarUsuario(cliente.codigo, this.state.form.contraseña)
                        .then(({ data }) => respuesta = data);
                } else {
                    await api.Clientes.modificarContraseña(cliente.codigo, this.state.form.contraseña)
                        .then(({ data }) => respuesta = data);
                }
                let userData;
                if (respuesta.trim() == 'OK') {
                    let pass = md5(this.state.form.contraseña);
                    localStorage.removeItem('user');
                    localStorage.removeItem('HuellaSi')
                    //Borrar las credenciales del usuario
                    /* NativeBiometric.deleteCredentials({
                         server: process.env.REACT_APP_APP_NAME,
                     }).then();*/

                    await api.Clientes.envioCliente(cliente.cif.trim().toUpperCase(), pass.trim()).then(
                        ({ data }) => (userData = data)
                    ).catch(function (err) {
                        console.log(err);
                    });
                    if (!Array.isArray(userData)) {
                        this.setState({ loading: false, error: null });
                        window.location = '/Login';
                    } else {

                        let usuario = {
                            "id": userData[0].id,
                            "usuario": userData[0].clienteid,
                            "codigo": userData[0].codigo,
                            "password": md5(pass.trim()),
                            "cif": cliente.cif,
                            "nombre": userData[0].nombre,
                            "apellidos": userData[0].apellidos,
                            "email": userData[0].email,
                            "telefono": userData[0].telefono1,
                            "movil": userData[0].movil,
                            "fechaFin": userData[0].fecha_fin,
                            "nombre_completo": userData[0].nombrecompleto,
                            "personaFisica": userData[0].personafisica,
                            "sms": userData[0].movil_sms,
                            "esUsuario": userData[0].esusuario,
                            "pass_cambiada": userData[0].cambio_pass_primer_acceso,
                            "lopd": userData[0].acepto_lopd
                        };

                        localStorage.setItem("user", JSON.stringify(usuario));
                        const cliente1 = JSON.parse(localStorage.getItem('user'));
                        this.setState({ loading: false, error: null });
                        this.abrirAlertOk();
                    }

                } else {
                    this.setState({ loading: false, error: null });
                    this.abrirAlertKo();
                }
            };
        } else {
            this.setState({ loading: false, error: null });
            this.abrirAlert();
        }
    }


    tooglePassword = e => {
        this.setState({ isRevealPassword: !this.state.isRevealPassword });
    }



    render() {
        const { t } = this.props;
        if (this.state.error === true) {
            return <PageError />;
        }
        if (this.state.loading == true) {
            return <PageLoading />;
        }
        return (
            <div className="h-100">
                <div className="titulo-pagina">
                    <span></span>
                </div>
                <form method="post" onSubmit={this.handleSubmit} className="formulario-contraseña">
                    {<span dangerouslySetInnerHTML={{ __html: this.state.texto }}></span>}
                    <div className="botones-linea botonespass">
                        <button type="submit" className="btn-aceptar" onChange={this.handleChange}>
                            <FontAwesomeIcon icon="circle-check" className="icon-btn" /> {t('Button.aceptar',)}
                        </button>
                    </div>
                </form>

                {this.props.error && (
                    <p className="password-danger">{this.props.error.message}</p>
                )}
                <PiePagina />
                <IonAlert
                    isOpen={this.state.alert}
                    cssClass='error'
                    header={t('CambioPass.noCoincide',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[`${t('Aceptar.acep',)}`]}
                />
                <IonAlert
                    isOpen={this.state.alertOk}
                    cssClass='correcto'
                    header={t('CambioPass.passOk',)}
                    message={`<img src="${Ok}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            window.location = '/Home';
                        }
                    }]}
                />
                <IonAlert
                    isOpen={this.state.alertKo}
                    cssClass='error'
                    header={t('CambioPass.passKo',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[`${t('Aceptar.acep',)}`]}
                />
            </div>
        );
    }

};
const Lopd = withTranslation('common')(LopdTranslated)
export default Lopd;